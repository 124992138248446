import React, { Component } from "react";
import { Link, NavLink } from "react-router-dom";
import { Dropdown, Button, MobileMenu } from "scripts/components/parts";
import { authenticationService } from "scripts/_services";
import { AppContext } from "scripts/context/app-context";
import { withRouter } from "react-router-dom";
import logoImg from "../../../images/logo.png";
import logoImg2x from "../../../images/logo@2x.png";

class Header extends Component {
  static contextType = AppContext;

  state = {
    isMobileMenuOpen: false,
  };

  componentDidUpdate(prevProps) {
    /* 
      If changed URL - for example clicked back button
      Close the menu
    */
    if (this.props.location !== prevProps.location) {
      this.setState({ isMobileMenuOpen: false });
    }
  }

  toggleMobileMenu = () => {
    const { isMobileMenuOpen } = this.state;
    this.setState({ isMobileMenuOpen: !isMobileMenuOpen });
  };

  closeMobileMenu = () => {
    this.setState({ isMobileMenuOpen: false });
  };

  renderMenu = (item, index) => {
    const { title, children, link } = item;
    let Tag = NavLink;
    const props = {};
    props.activeClassName = "is-current";
    props.to = link || "";

    if (link) {
      if (children) {
        props.onClick = (e) => {
          e.preventDefault();
        };
      } else {
        // Tag = NavLink;
      }
    }

    return (
      <div className={`header__menu-item`} key={index}>
        <Tag
          {...props}
          className={`header__menu-title header__menu-title--${
            children ? "dropdown" : ""
          }`}
        >
          {title}
        </Tag>
        {children ? (
          <div className={`header__menu-dropdown`}>
            <Dropdown items={children} />
          </div>
        ) : (
          ""
        )}
      </div>
    );
  };

  render() {
    const { isMobileMenuOpen } = this.state;
    const { currentUserValue: currentUser, checkAccess } =
      authenticationService;

    const menu = [
      {
        title: "Service",
        link: "/service-call",
        children: [{ title: "Select equipment", link: "/service-call" }],
      },
      {
        title: "Meter readings",
        link: "/meter-readings",
      },
      {
        title: "Accounts",
        link: "/accounts",
        children: [
          { title: "Our contacts", link: "/accounts/our-contacts" },
          { title: "Service history", link: "/accounts/service-history" },
          {
            title: "Our usage",
            children: [
              { title: "Unpaid invoices", link: "/accounts/unpaid-invoices" },
              { title: "Billed usage", link: "/accounts/billed-usage" },
            ],
          },
        ],
      },

      {
        title: "Consumables",
        link: "/pending-order-management",
        children: [
          {
            title: "Order consumables",
            href: "http://direct-fbnz.fujifilm.com",
            target: "_blank",
          },
          {
            title: "Pending order management",
            link: "/pending-order-management",
          },
          {
            title: "Order Supplies",
            href: "http://supplies.kmnz.co.nz",
            target: "_blank",
          },
          {
            title: "Consumable Returns Policy",
            link: "/consumable-returns-policy",
          },
        ],
      },
      {
        title: "Contact us",
        link: "/contact-us",
      },
    ];

    const mobile_menu = currentUser
      ? menu.concat([
          { title: "My details", link: "/my-details" },
          /*{ title: "Contact us", link: "/contact-us" },*/
          { title: "Log out", link: "/log-out" },
        ])
      : [
          { title: "Log in", link: "/login" },
          { title: "Forgotten details", link: "/forgotten-details" },
          { title: "Register new user", link: "/register" },
          { title: "Contact us", link: "/contact-us" },
        ];

    let user_menu = [
      { title: "My details", link: "/my-details" },
      { title: "Log out", link: "/log-out" },
    ];

    // Adding switch account item if user has permissions
    if (checkAccess("switch_accounts")) {
      user_menu.splice(1, 0, {
        title: "Switch accounts",
        link: "/switch-accounts",
      });

      mobile_menu.splice(mobile_menu.length - 2, 0, {
        title: "Switch accounts",
        link: "/switch-accounts",
      });
    }

    const logo = (
      <img src={logoImg} srcSet={`${logoImg} 1x, ${logoImg2x} 2x`} alt="Logo" />
    );

    return (
      <div
        className={`header ${isMobileMenuOpen ? "is-mobile-menu-open" : ""}`}
      >
        <div className={`header__top`}>
          <div className={`header__logo`}>
            {currentUser ? (
              <Link to="/">{logo}</Link>
            ) : (
              <a
                href="https://www.fujifilm.com/fbnz/"
                rel="noopener noreferrer"
                target="_blank"
              >
                {logo}
              </a>
            )}
          </div>

          {currentUser && (
            <nav className={`header__menu`}>{menu.map(this.renderMenu)}</nav>
          )}

          <nav className={`header__secondary`}>
            {currentUser ? (
              <div className={`header__link`}>
                <div className={`header__account`}>
                  <img
                    src={require("../../../images/account.svg")}
                    className={`header__account-icon`}
                    alt={`${currentUser.firstName} ${currentUser.lastName}`}
                  />
                  <div className={`header__account-name`}>
                    {currentUser.firstName} {currentUser.lastName}{" "}
                  </div>

                  {currentUser.client ? (
                    <div className="header__account-company">
                      ({currentUser.client.name})
                    </div>
                  ) : null}
                </div>
                <div className={`header__account-dropdown`}>
                  <Dropdown items={user_menu} />
                </div>
              </div>
            ) : (
              <div className={`header__link`}>
                <Button modifier={`text`} to="/contact-us">
                  Contact us
                </Button>
              </div>
            )}
          </nav>

          <div className={`header__burger`} onClick={this.toggleMobileMenu}>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>

        <div className={`header__mobile-menu`}>
          <MobileMenu menu={mobile_menu} toggle={this.toggleMobileMenu} />
        </div>

        <div
          className={`header__mobile-overlay`}
          onClick={this.closeMobileMenu}
        ></div>
      </div>
    );
  }
}

export default withRouter(Header);

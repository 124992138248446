import React, { Component } from "react";
import { Link } from "react-router-dom";

class Card extends Component {
  render() {
    const { title, icon, to } = this.props;

    return (
      <Link to={to} className={`card `}>
        <div className={`card__icon`}>{icon}</div>
        <div
          className={`card__title`}
          dangerouslySetInnerHTML={{ __html: title }}
        />
      </Link>
    );
  }
}

export { Card };

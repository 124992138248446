import React, { Component } from "react";
import { LayoutPage } from "scripts/components/layouts";
import { authenticationService } from "scripts/_services";
import { Card } from "scripts/components/parts";
import { IconInk } from "scripts/components/parts/Icons/IconInk";
import { IconPrinter } from "scripts/components/parts/Icons/IconPrinter";
import { IconTools } from "scripts/components/parts/Icons/IconTools";
import { IconMeter } from "scripts/components/parts/Icons/IconMeter";
import { colors } from "scripts/vars/colors";

class Home extends Component {
  render() {
    const { currentUserValue: currentUser } = authenticationService;

    return (
      <LayoutPage grey>
        <div className="home">
          <h3 className="home__h">
            Welcome to MyAccount {currentUser.firstName}
          </h3>
          <div className="home__sub">({currentUser.client.name})</div>
          {/* <div className="home__text">
            <p>MyAccount is a central place to:</p>
            <ul>
              <li>
                Change details for your account, service, or billing contacts
              </li>
              <li>Update location details for your Fujifilm equipment</li>
              <li>Enter meter readings</li>
              <li>Log service calls</li>
            </ul>
          </div> */}
          <div className="home__cards">
            <Card
              to="/service-call"
              icon={<IconTools color={colors.emerald} />}
              title="SERVICE"
            />
            <Card
              to="/meter-readings"
              icon={<IconMeter color={colors.emerald} />}
              title="METER READINGS"
            />
            <Card
              to="/accounts/our-contacts"
              icon={<IconPrinter color={colors.emerald} />}
              title="ACCOUNTS"
            />
            <Card
              to="/pending-order-management"
              icon={<IconInk color={colors.emerald} />}
              title="CONSUMABLES"
            />
          </div>

          <div className="home__banner">
            <a
              href="https://www-fbnz.fujifilm.com/"
              rel="noopener noreferrer"
              target="_blank"
            >
              <img src={require("images/home/welcome-banner.jpg")} alt="" />
            </a>
          </div>

          <div className="home__headlines">
            <div className="home__headline">
              <h4>Your Feedback Matters</h4>
              <p>
                We’re always keen to hear from our customers, so please give us
                your feedback through our Voice of Customer portal{" "}
                <a
                  href="https://survey4.medallia.com/?FujiFilmFBNZ&la=1&l=8"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  here
                </a>
                .
              </p>
            </div>

            <div className="home__headline">
              <h4>Courier Service Delays</h4>
              <p>
                Most national courier services are currently running behind
                schedule, so be sure to order any consumables like toner a few
                days in advance.
              </p>
            </div>
          </div>
        </div>
      </LayoutPage>
    );
  }
}

export default Home;
